<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="livraisons"
          item-key="nom"
          class="table-rounded"
          hide-default-footer
        >
          <!-- name -->
          <template #[`item.nom`]="{item}">
            <div class="d-flex flex-column">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.nom }}</span>
            </div>
          </template>
          <!-- compte -->
          <template #[`item.nbleads`]="{item}">
            <v-chip
              small
              class="font-weight-medium"
            >
              {{ (item.leads).length }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <!-- Ajout Livraison -->
    <v-col
      cols="12"
      md="6"
      lg="4"
      class="align-self-start"
    >
      <v-card color="text-center">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar
            color="primary"
            class="mt-10"
            icon
            size="50"
          >
            <v-icon
              size="2rem"
              color="white"
            >
              {{ icons.mdiChartBoxPlusOutline }}
            </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 font-weight-medium">
            Nouvelle Livraison
          </h6>
        </v-card-text>
        <v-card-text>
          According to us blisters are a very common thing and we come across them very often in our daily lives. It is a very common occurrence like cold or fever depending upon your lifestyle.
        </v-card-text>
        <v-card-text>
          <v-btn
            color="primary"
            class="mt-4"
            :to="{ name: 'NewLivraison' }"
          >
            Ajouter Livraison
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import axios from 'axios'
import {
  mdiChartBoxPlusOutline,
} from '@mdi/js'

export default {
  components: {
  },
  data() {
    return {
      livraisons: [],
      newFile: [],
    }
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}/livraisons`, {}).then(
      response => {
        this.livraisons = response.data
        console.log(this.livraisons)
      },
      error => {
        console.log(error)
      },
    )
  },
  setup() {
    return {
      headers: [
        { text: 'NOM', value: 'nom' },
        { text: 'PRIX', value: 'prix' },
        { text: 'PARTENAIRE', value: 'partenaire' },
        { text: 'DATE', value: 'created_at' },
        { text: 'LEADS', value: 'nbleads' },
      ],
      icons: {
        mdiChartBoxPlusOutline,
      },
    }
  },
  methods: {
  },
}
</script>
